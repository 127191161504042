<template>
  <div>
    <v-card>
      <v-card-title>
        Termo LGPD
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex flex-row-reverse pb-4">
        <v-btn
          v-if="isFiltered"
          class="me-3"
          outlined
          color="warning"
          :disabled="isLoadingExport"
          @click="exportCsv()"
        >
          <div
            v-if="!isLoadingExport"
            class=" d-flex items-center align-center"
            style=" gap: 10px;"
          >
            <span class="button-text-export">
              EXPORTAR
            </span>
            <v-img
              height="17"
              width="20"
              :src="mdiTrayArrowDown"
            ></v-img>
          </div>
          <v-progress-circular
            v-else
            color="warning"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>

        <v-btn
          :color="filterButtonColor"
          class="mr-4"
          outlined
          @click="handleOpenModalFilterTerms"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          Filtrar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoadingTableData"
        loading-text="Carregando dados..."
        fixed-header
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip
            top
            color="#000000A1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class=""
                color="#E7E3FC"
                v-bind="attrs"
                v-on="on"
                @click="handleOpenModalVisualizeTerm(item)"
              >
                <v-icon>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Visualizar termo</span>
          </v-tooltip>
          <v-tooltip
            top
            color="success"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class=""
                color="success"
                v-bind="attrs"
                v-on="on"
                @click="sendingSignedDocto(item)"
              >
                <v-icon v-if="!item.isLoading">
                  {{ icons.mdiFileSendOutline }}
                </v-icon>
                <v-progress-circular
                  v-else
                  color="success"
                  indeterminate
                >
                </v-progress-circular>
              </v-btn>
            </template>
            <span>Enviar documento assinado</span>
          </v-tooltip>
          <v-tooltip
            top
            color="info"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class=""
                color="info"
                v-bind="attrs"
                v-on="on"
                @click="handleOpenUpdateModal(item)"
              >
                <v-icon>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Editar termo</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <div class="my-5">
            <NoDataInterfaceEditable
              title="Nenhum  registro encontrado!"
              subtitle="Por favor, verifique os filtros de pesquisa."
            />
          </div>
        </template>
        <template v-slot:item.proposal_number="{ item }">
          <span>{{ item.proposal_number ? returnProposalNumberFormatted(item.proposal_number) : '-' }}</span>
        </template>
        <template v-slot:item.life_insurance="{ item }">
          <span>{{ item.life_insurance ? 'SIM' : '-' }}</span>
        </template>
        <template v-slot:item.bid_fixed="{ item }">
          <span>{{ item.bid_fixed ? 'SIM' : '-' }}</span>
        </template>
        <template v-slot:item.client_name="{ item }">
          <span>{{ item.client_name ? item.client_name.toUpperCase() : '-' }}</span>
        </template>
        <template v-slot:item.client_phone="{ item }">
          <span>{{ item.client_phone ? item.client_phone.toUpperCase() : '-' }}</span>
        </template>
        <template v-slot:item.vendor_name="{ item }">
          <span>{{ item.vendor_name ? item.vendor_name.toUpperCase() : '-' }}</span>
        </template>
        <template v-slot:item.contract_date="{ item }">
          <span>{{ item.contract_date ? returnDateFormatted(item.contract_date): '-' }}</span>
        </template>
        <template v-slot:item.term_status="{ item }">
          <v-alert
            class="badge my-5"
            :color="getStatusColor(item)"
            text
            style="border-radius: 6px; font-size: 14px; font-weight: 500;"
          >
            {{ item.term_status ? item.term_status.toUpperCase() : '' }}
          </v-alert>
        </template>
      </v-data-table>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- <v-card-text class="pt-2 pb-1">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-center"
          >
            <v-pagination
              v-model="pageOptions.page"
              total-visible="6"
              :length="pageOptions.pageCount"
              @input="updatePage($event)"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text> -->
    </v-card>
    <v-dialog
      v-model="showModalFilterTerms"
      width="600px"
      transition="dialog-transition"
    >
      <ModalFilterTerms
        ref="ModalFilterTermsRef"
        :list-store="listStore"
        :list-vehicle-model="listVehicleModel"
        :list-region="listRegion"
        :vendor-list="vendorList"
        @close="showModalFilterTerms = false"
        @filterData="handleFilterData"
      />
    </v-dialog>
    <v-dialog
      :key="updateModalKey"
      v-model="showModalUpdateTerm"
      width="900px"
      transition="dialog-transition"
    >
      <ModalUpdateTerm
        :list-store="listStore"
        :list-vehicle-model="listVehicleModel"
        :list-region="listRegion"
        :item="selectedItem"
        @close="showModalUpdateTerm = false"
      />
    </v-dialog>
    <v-dialog
      :key="visualizeModalKey"
      v-model="showModalVisualizeTerm"
      width="900px"
      transition="dialog-transition"
    >
      <ModalVisualizeTerm
        :list-store="listStore"
        :list-vehicle-model="listVehicleModel"
        :list-region="listRegion"
        :item="selectedItem"
        @close="showModalVisualizeTerm = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import mdiTrayArrowDown from '@/assets/images/svg/mdiTrayArrowDown.svg'
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import {
  mdiAccountOutline,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiDownload,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFileSendOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterPlusOutline,
  mdiFilterRemove,
  mdiFilterRemoveOutline,
  mdiFilterVariant,
  mdiLoading,
  mdiMagnify,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalFilterTerms from './ModalFilterTerms.vue'
import ModalUpdateTerm from './ModalUpdateTerm.vue'
import ModalVisualizeTerm from './ModalVisualizeTerm.vue'

const ExcelJS = require('exceljs')

export default {

  components: {
    NoDataInterfaceEditable,
    ModalFilterTerms,
    ModalUpdateTerm,
    ModalVisualizeTerm,
  },
  data() {
    return {
      mdiTrayArrowDown,
      isLoadingTableData: false,
      isFiltered: false,
      showModalFilterTerms: false,
      showModalUpdateTerm: false,
      showModalVisualizeTerm: false,
      visualizeModalKey: '1234',
      updateModalKey: '12434',
      isLoadingExport: false,
      headers: [
        { text: 'DATA', value: 'contract_date' },
        { text: 'PROPOSTA', value: 'proposal_number' },
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'VENDEDOR', value: 'vendor_name' },
        { text: 'TELEFONE DO CLIENTE', value: 'client_phone' },
        { text: 'MODELO', value: 'vehicle_model.vehicle_model' },
        { text: 'SEGURO', value: 'life_insurance', align: 'center' },
        { text: 'LANCE', value: 'bid_fixed', align: 'center' },
        { text: 'STATUS', value: 'term_status', align: 'center' },
        { text: 'OPÇÕES', value: 'actions', align: 'center' },
      ],
      termLgpdList: [],
      listStore: [],
      listVehicleModel: [],
      listRegion: [],
      vendorList: [],
      listOfFilteredItems: [],
      selectedItem: {},
      icons: {
        mdiDownload,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiFileDocumentOutline,
        mdiPencil,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiFilterVariant,
        mdiAccountOutline,
        mdiFilter,
        mdiFilterRemove,
        mdiWhatsapp,
        mdiCalendar,
        mdiFilterMenu,
        mdiLoading,
        mdiEyeOutline,
        mdiMagnify,
        mdiTextBoxSearch,
        mdiFileSendOutline,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  created() {
    this.vendorList = localStorageSlim.get('listEmployees', { decrypt: true })
    this.getListStore()
    this.getListRegion()
    this.getListVehicleModel()
  },
  methods: {
    getStatusColor(item = { term_status: '' }) {
      // eslint-disable-next-line camelcase
      const { term_status } = item

      const termStatusColorMap = {
        Assinada: 'warning',
        Inutilizada: 'error',
        Validada: 'success',
        Aberta: 'white',
      }

      return termStatusColorMap[term_status] ?? ''
    },
    async confirmAction() {
      const result = await this.$swal({
        title: 'Atenção!',
        text: 'Você tem certeza que deseja enviar o documento assinado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },
    async sendingSignedDocto(item) {
      const confirmAction = await this.confirmAction()
      if (!confirmAction) return
      item.isLoading = true
      const data = {
        id: item.id,
      }
      try {
        await axiosIns.post('/api/v1/integration/lgpd_term/sending_signed_docto_client', data)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'O termo foi enviado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao enviar o termo!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        item.isLoading = false
      }
    },
    async exportCsv(item) {
      this.isLoadingExport = true
      const file = await this.getCsv(item)

      if (file) {
        saveAs(file)
      }
    },
    handleOpenModalVisualizeTerm(item) {
      this.visualizeModalKey = Math.random().toString(36).substring(7)
      this.showModalVisualizeTerm = true
      this.selectedItem = item
    },
    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'DATA', key: 'contract_date', width: 25 },
        { header: 'PROPOSTA', key: 'proposal_number', width: 25 },
        { header: 'CLIENTE', key: 'client_name', width: 25 },
        { header: 'VENDEDOR', key: 'vendor_name', width: 25 },
        { header: 'CELULAR', key: 'client_phone', width: 25 },
        { header: 'MODELO', key: 'vehicle_model.vehicle_model', width: 25 },
        { header: 'SEGURO', key: 'life_insurance', width: 25 },
        { header: 'LANCE', key: 'bid_fixed', width: 25 },
      ]
      const headerRow = worksheet.getRow(1)
      headerRow.eachCell(cell => {
        // eslint-disable-next-line no-param-reassign
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '31869B' },
        }
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          color: { argb: 'FFFFFF' },
          bold: true,
        }
        // eslint-disable-next-line no-param-reassign
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      })
      worksheet.autoFilter = {
        from: 'A1',
        to: 'H1',
      }

      this.listOfFilteredItems.map(item => {
        worksheet.addRow([
          this.returnDateFormatted(item.contract_date) || '-',
          this.returnProposalNumberFormatted(item.proposal_number) || '-',
          (item.client_name || '-').toUpperCase(),
          (item.vendor_name || '-').toUpperCase(),
          item.client_phone || '-',
          (item.vehicle_model?.vehicle_model || '-'),
          item.life_insurance ? 'SIM' : 'NÃO',
          item.bid_fixed ? 'SIM' : 'NÃO',
        ])
      })
      worksheet.columns.forEach(column => {
        column.eachCell(cell => {
          // eslint-disable-next-line no-param-reassign
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
        })
      })

      const now = new Date()
      const formattedDate = `${now.getDate().toString().padStart(2, '0')}_${(now.getMonth() + 1).toString().padStart(2, '0')}_${now.getFullYear()}`
      const formattedTime = `${now.getHours().toString().padStart(2, '0')}.${now.getMinutes().toString().padStart(2, '0')}.${now.getSeconds().toString().padStart(2, '0')}`
      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], `Termo LGPD - ${formattedDate} - ${formattedTime}.xlsx`, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.isLoadingExport = false

      return blob
    },
    async getListVehicleModel() {
      try {
        const { data } = await axiosIns.get('/api/v1/integration/vehicle_model/index')

        this.listVehicleModel = data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de Veículos!',
          text: error,
          icon: 'error',
        })
      }
    },
    async getListStore() {
      try {
        const { data } = await axiosIns.get('/api/v1/records/company/index')

        this.listStore = data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de lojas!',
          text: error,
          icon: 'error',
        })
      }
    },
    async getListRegion() {
      try {
        const { data } = await axiosIns.get('/api/v1/records/region/index')

        this.listRegion = data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de regiões!',
          text: error,
          icon: 'error',
        })
      }
    },
    handleOpenUpdateModal(item) {
      this.updateModalKey = Math.random().toString(36).substring(7)
      this.showModalUpdateTerm = true
      this.selectedItem = item
    },
    handleFilterData(data) {
      this.listOfFilteredItems = data.data.map(item => ({
        ...item,
        isLoading: false,
      }))
      this.isFiltered = data.isFiltered
    },
    handleOpenModalFilterTerms() {
      this.showModalFilterTerms = true
    },
    returnProposalNumberFormatted(proposalNumber) {
      if (!proposalNumber.includes('-')) {
        return `${proposalNumber.slice(0, -1)}-${proposalNumber.slice(-1)}`
      }

      return proposalNumber
    },
    returnDateFormatted(date) {
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },

}
</script>

<style>
.button-text-export{
  font-size: 14px;
  font-weight: 600;
}
</style>
