<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Editar termo
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="mx-7">
      <v-form ref="form">
        <div class="pb-8">
          <Separator label="Informações do cliente" />
        </div>
        <div
          class="d-flex"
          style="gap: 10px; align-items: center;"
        >
          <v-text-field
            v-model="actualData.client_name"
            readonly
            outlined
            dense
            label="Nome do cliente"
          />
          <v-text-field
            v-model="actualData.cpf_cnpj"
            readonly
            outlined
            dense
            label="CPF/CNPJ"
          />
        </div>
        <div
          class="d-flex"
          style="gap: 5px; width: 100%;"
        >
          <v-text-field
            v-model="actualData.client_phone"
            readonly
            outlined
            dense
            label="Telefone"
            style="width: 50%;"
          />
          <div style="width: 50%; padding-left: 5px;">
            <v-text-field
              disabled
              label="Assinatura"
              class="pt-0"
              hide-details
            ></v-text-field>
            <v-img
              class="mb-10"
              :src="signature"
            ></v-img>
          </div>
        </div>
        <div
          class="d-flex"
          style="gap: 10px; align-items: center;"
        >
        </div>
        <div class="pb-8">
          <Separator label="Dados da proposta" />
        </div>

        <div>
          <v-text-field
            v-model="actualData.proposal_number"
            outlined
            dense
            label="Número da proposta"
          />
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contract_date_formatted"
                label="Data do contrato"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(contract_date_formatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="actual_contract_date"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-autocomplete
            v-model="actualData.vehicle_model"
            label="Modelo"
            :items="listVehicleModel"
            item-text="vehicle_model"
            item-value="id"
            outlined
            dense
          />
        </div>
        <div
          class="d-flex"
          style="gap: 77px;"
        >
          <v-switch
            v-model="actualData.bid_fixed"
            class="mt-0"
            color="success"
            inset
            dense
            label="Lance fixo"
          />
          <v-switch
            v-model="actualData.life_insurance"
            class="mt-0"
            color="purple"
            inset
            dense
            label="Seguro de vida"
          />
        </div>

        <div class="pb-8">
          <Separator label="Dados do vendedor" />
        </div>
        <div
          class="d-flex"
          style="gap: 10px;"
        >
          <v-text-field
            v-model="actualData.vendor_name"
            readonly
            outlined
            dense
            label="Nome do vendedor"
          />
          <v-text-field
            v-model="actualData.vendor_cpf"
            readonly
            outlined
            dense
            label="CPF do vendedor"
          />
        </div>
        <div
          class=" d-flex justify-between pb-4"
        >
          <v-btn
            color="error"
            @click="closeModal"
          >
            <span>CANCELAR</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            :disabled="!isDataChanged || isLoading"
            @click="handleUpdateTerm"
          >
            <span>SALVAR</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import { mdiClose } from '@mdi/js'

export default {

  components: {
    Separator,
  },

  props: {
    listRegion: {
      type: Array,
      default: () => [],
    },
    listStore: {
      type: Array,
      default: () => [],
    },
    listVehicleModel: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      signature: '',
      icons: {
        mdiClose,
      },
      actualData: {
        client_name: '',
        cpf_cnpj: '',
        client_phone: '',
        proposal_number: '',
        vendor_name: '',
        vendor_cpf: '',
        contract_date: '',
        vehicle_model: '',
        bid_fixed: false,
        life_insurance: false,
      },
      isDataChanged: false,
      menu1: false,
      isLoading: false,
      oldObject: {},
      contract_date_formatted: '',
      actual_contract_date: '',
    }
  },

  watch: {
    actual_contract_date: {
      handler() {
        this.contract_date_formatted = this.formatDate(this.actual_contract_date)
        const newObject = {
          proposal_number: this.actualData.proposal_number,
          contract_date: this.actual_contract_date,
          vehicle_model: this.actualData.vehicle_model.id,
          bid_fixed: this.actualData.bid_fixed,
          life_insurance: this.actualData.life_insurance,
        }
        this.isDataChanged = JSON.stringify(this.oldObject) !== JSON.stringify(newObject)
      },
    },
    actualData: {
      handler() {
        const newObject = {
          proposal_number: this.actualData.proposal_number,
          contract_date: this.actual_contract_date,
          vehicle_model: this.actualData.vehicle_model.id,
          bid_fixed: this.actualData.bid_fixed,
          life_insurance: this.actualData.life_insurance,
        }
        this.isDataChanged = JSON.stringify(this.oldObject) !== JSON.stringify(newObject)
      },
      deep: true, // Observa mudanças profundas no objeto
    },
  },

  created() {
    this.actual_contract_date = this.item.contract_date
    this.handleGetSignature()
    this.oldObject = {
      proposal_number: this.item.proposal_number,
      contract_date: this.item.contract_date,
      vehicle_model: this.item.vehicle_model.id,
      bid_fixed: this.item.bid_fixed,
      life_insurance: this.item.life_insurance,
    }
    this.actualData = {
      client_name: this.item.client_name,
      cpf_cnpj: this.item.cpf_cnpj,
      client_phone: this.item.client_phone,
      proposal_number: this.item.proposal_number,
      contract_date: this.item.contract_date,
      vehicle_model: this.item.vehicle_model,
      bid_fixed: this.item.bid_fixed,
      life_insurance: this.item.life_insurance,
      vendor_name: this.item.vendor_name,
      vendor_cpf: this.item.vendor_cpf,
    }
  },
  methods: {
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    async handleUpdateTerm() {
      this.isLoading = true
      console.log(this.actualData.vehicle_model)
      const body = {
        proposal_number: this.actualData.proposal_number,
        contract_date: this.actual_contract_date,
        vehicle_models_id: this.actualData.vehicle_model.id || this.actualData.vehicle_model,
        bid_fixed: this.actualData.bid_fixed,
        life_insurance: this.actualData.life_insurance,
      }
      try {
        await axiosIns.put(`/api/v1/integration/lgpd_term/update/${this.item.id}`, body)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Termo atualizado com sucesso!',
          showConfirmButton: false,
          timer: 2500,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao atualizar o termo!',
          showConfirmButton: false,
          timer: 2500,
        })
      } finally {
        this.isLoading = false
        this.isDataChanged = false
      }
    },
    closeModal() {
      this.$emit('close')
    },
    async handleGetSignature() {
      try {
        const { data } = await axiosIns.get(`/api/v1/integration/lgpd_term/edit/${this.item.id}`)
        this.signature = data.data.customer_subscription
        console.log(data)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao trazer a assinatura do cliente!',
          showConfirmButton: false,
          timer: 2500,
        })
      }
    },
  },
}
</script>
