<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <div
        style="position: absolute; left: 20px; top: 25px;"
      >
        <v-menu
          offset-y
          location="bottom"
        >
          <template v-slot:activator="{ attrs, on }">
            <div
              outlined
              v-bind="attrs"
              class="container-options"
              v-on="on"
            >
              <v-icon
                size="22"
                class=""
              >
                {{ icons.mdiCogOutline }}
              </v-icon>

              <span class="text-md-subtitle-2">Opções</span>

              <v-img
                class="arrow"
                :width="12"
                src="@/assets/arrow-down-icon.svg"
              ></v-img>
            </div>
          </template>

          <v-list>
            <v-list-item
              v-for="options in listOptions"
              :key="options.value"
              link
            >
              <v-icon
                :color="options.color"
                small
                class="me-2"
              >
                {{ options.icons }}
              </v-icon>
              <v-list-item-title
                @click="options.onAction()"
                v-text="options.title"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Visualizar termo
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="mx-7 pt-2">
      <v-form ref="form">
        <div class="pb-8">
          <Separator label="Informações do cliente" />
        </div>
        <div
          class="d-flex"
          style="gap: 10px; align-items: center;"
        >
          <v-text-field
            v-model="actualData.client_name"
            readonly
            outlined
            dense
            label="Nome do cliente"
          />
          <v-text-field
            v-model="actualData.cpf_cnpj"
            readonly
            outlined
            dense
            label="CPF/CNPJ"
          />
        </div>
        <div
          class="d-flex"
          style="gap: 5px; width: 100%;"
        >
          <v-text-field
            v-model="actualData.client_phone"
            readonly
            outlined
            dense
            label="Telefone"
            style="width: 50%;"
          />
          <div style="width: 50%; padding-left: 5px;">
            <v-text-field
              disabled
              label="Assinatura"
              class="pt-0"
              hide-details
            ></v-text-field>
            <v-img
              class="mb-10"
              :src="signature"
            ></v-img>
          </div>
        </div>
        <div
          class="d-flex"
          style="gap: 10px; align-items: center;"
        >
        </div>
        <div class="pb-8">
          <Separator label="Dados da proposta" />
        </div>

        <div>
          <v-text-field
            v-model="actualData.proposal_number"
            outlined
            readonly
            dense
            label="Número da proposta"
          />
          <v-text-field
            v-model="contract_date_formatted"
            label="Data da venda"
            :append-icon="icons.mdiCalendar"
            readonly
            outlined
            dense
          ></v-text-field>
        </div>
        <div>
          <v-autocomplete
            v-model="actualData.vehicle_model"
            label="Modelo"
            :items="listVehicleModel"
            item-text="vehicle_model"
            readonly
            item-value="id"
            outlined
            dense
          />
        </div>
        <div
          class="d-flex"
          style="gap: 77px;"
        >
          <v-switch
            v-model="actualData.bid_fixed"
            class="mt-0"
            color="success"
            inset
            readonly
            dense
            label="Lance fixo"
          />
          <v-switch
            v-model="actualData.life_insurance"
            class="mt-0"
            color="purple"
            readonly
            inset
            dense
            label="Seguro de vida"
          />
        </div>

        <div class="pb-8">
          <Separator label="Dados do vendedor" />
        </div>
        <div
          class="d-flex"
          style="gap: 10px;"
        >
          <v-text-field
            v-model="actualData.vendor_name"
            readonly
            outlined
            dense
            label="Nome do vendedor"
          />
          <v-text-field
            v-model="actualData.vendor_cpf"
            readonly
            outlined
            dense
            label="CPF do vendedor"
          />
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import {
  mdiCancel, mdiCheckDecagram, mdiClose,
  mdiCogOutline,
  mdiDraw, mdiFileExportOutline, mdiFileSendOutline,
} from '@mdi/js'

export default {

  components: {
    Separator,
  },

  props: {
    listRegion: {
      type: Array,
      default: () => [],
    },
    listStore: {
      type: Array,
      default: () => [],
    },
    listVehicleModel: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      signature: '',
      icons: {
        mdiClose, mdiCogOutline, mdiDraw, mdiFileSendOutline, mdiCheckDecagram, mdiCancel,
      },
      actualData: {
        client_name: '',
        cpf_cnpj: '',
        client_phone: '',
        proposal_number: '',
        vendor_name: '',
        vendor_cpf: '',
        contract_date: '',
        vehicle_model: '',
        bid_fixed: false,
        life_insurance: false,
      },
      listOptions: [
        {
          title: 'Refazer',
          value: 'refazer',
          icons: mdiDraw,
          color: 'warning',
          onAction: () => this.signAgainTermLgpd(),
        },
        {
          title: '2º Via',
          value: '2º via',
          icons: mdiFileSendOutline,
          color: 'info',
          onAction: () => this.sendingSignedDoctoClient(),
        },
        {
          title: 'Validar termo',
          value: 'validar',
          icons: mdiCheckDecagram,
          color: 'success',
          onAction: () => this.validateTermLgpd(),
        },
        {
          title: 'Inutilizar',
          value: 'inutilizar',
          icons: mdiCancel,
          color: 'error',
          onAction: () => this.disabledTermLgpd(),
        },
        {
          title: 'Obter PDF',
          value: 'Obter PDF',
          icons: mdiFileExportOutline,
          color: 'purple',
          onAction: () => this.getPDF(),
        },
      ],
      isLoading: false,
    }
  },

  created() {
    this.handleGetSignature()
    this.actualData = {
      client_name: this.item.client_name,
      cpf_cnpj: this.item.cpf_cnpj,
      client_phone: this.item.client_phone,
      proposal_number: this.item.proposal_number,
      contract_date: this.item.contract_date,
      vehicle_model: this.item.vehicle_model,
      bid_fixed: this.item.bid_fixed,
      life_insurance: this.item.life_insurance,
      vendor_name: this.item.vendor_name,
      vendor_cpf: this.item.vendor_cpf,
    }
    this.contract_date_formatted = this.formatDate(this.actualData.contract_date)
  },
  methods: {
    async confirmAction(text, title) {
      const result = await this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },
    exportPDF(data) {
      const pdfBlob = new Blob([data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    },
    async getPDF() {
      const confirmAction = await this.confirmAction('Deseja realmente obter o PDF?', 'Obter PDF')
      if (!confirmAction) return
      const { id } = this.item
      this.isLoadingExportPdf = true

      try {
        const response = await axiosIns.get(`api/lgpd_term/write_pdf_term/${id}`, { responseType: 'blob' })
        this.exportPDF(response.data)
      } catch (error) {
        this.$swal({
          title: 'Erro.',
          text: 'Erro ao obter PDF',
          icon: 'error',
          timer: 3000,
          showConfirmButton: false,
        })
      } finally {
        this.isLoadingExportPdf = false
      }
    },
    async signAgainTermLgpd() {
      const confirmAction = await this.confirmAction('Deseja realmente refazer o termo?', 'Refazer termo')
      if (!confirmAction) return
      this.loadingButtonSignAgain = true

      try {
        await axiosIns.get(`/api/v1/integration/lgpd_term/sign_again/${this.item.id}`)
        this.$swal({
          icon: 'success',
          title: 'Sucesso.',
          text: 'Termo enviado para refazer!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro.',
          showConfirmButton: false,
          text: 'Erro ao enviar para refazer o termo!',
          timer: 3000,
        })
      } finally {
        this.loadingButtonSignAgain = false
      }
    },
    async sendingSignedDoctoClient() {
      const confirmAction = await this.confirmAction('Deseja realmente enviar a 2° via do termo?', '2° via do termo')
      if (!confirmAction) return
      this.loadingButtonSendSigned = true
      const data = {
        id: this.item.id,
      }
      try {
        await axiosIns.post('/api/v1/integration/lgpd_term/sending_signed_docto_client', data)
        this.$swal({
          icon: 'success',
          title: 'Sucesso.',
          text: '2° via do termo enviado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro.',
          text: 'Ocorreu um erro ao enviar a 2° via do termo!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.loadingButtonSendSigned = false
      }
    },
    async validateTermLgpd() {
      const confirmAction = await this.confirmAction('Deseja realmente validar o termo?', 'Validar termo')
      if (!confirmAction) return
      this.loadingButtonValid = true
      try {
        await axiosIns.get(`/api/v1/integration/lgpd_term/validate/${this.item.id}`)
        this.$swal({
          icon: 'success',
          title: 'Sucesso.',
          text: 'Termo validado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao validar o termo!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.loadingButtonValid = false
      }
    },
    async disabledTermLgpd() {
      const confirmAction = await this.confirmAction('Deseja realmente inutilizar o termo?', 'Inutilizar termo')
      if (!confirmAction) return
      this.loadingButtonDisable = true
      try {
        axiosIns.get(`/api/v1/integration/lgpd_term/disabled/${this.item.id}`)
        this.$swal({
          icon: 'success',
          title: 'Sucesso.',
          text: 'Termo inutilizado com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao inutilizar o termo!',
          showConfirmButton: false,
          timer: 3000,
        })
      } finally {
        this.loadingButtonDisable = false
      }
    },
    formatDate(date) {
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    closeModal() {
      this.$emit('close')
    },
    async handleGetSignature() {
      try {
        const { data } = await axiosIns.get(`/api/v1/integration/lgpd_term/edit/${this.item.id}`)
        this.signature = data.data.customer_subscription
        console.log(data)
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao trazer a assinatura do cliente!',
          showConfirmButton: false,
          timer: 2500,
        })
      }
    },
  },
}
</script>

<style>
  .container-options{
    padding: 8px;
    border-radius: 60px;

    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .container-options:hover{
    background: #3B355A;
  }
</style>
