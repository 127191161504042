import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Termo LGPD ")]),_c(VDivider,{staticClass:"mt-0"}),_c(VCardText,{staticClass:"d-flex flex-row-reverse pb-4"},[(_vm.isFiltered)?_c(VBtn,{staticClass:"me-3",attrs:{"outlined":"","color":"warning","disabled":_vm.isLoadingExport},on:{"click":function($event){return _vm.exportCsv()}}},[(!_vm.isLoadingExport)?_c('div',{staticClass:" d-flex items-center align-center",staticStyle:{"gap":"10px"}},[_c('span',{staticClass:"button-text-export"},[_vm._v(" EXPORTAR ")]),_c(VImg,{attrs:{"height":"17","width":"20","src":_vm.mdiTrayArrowDown}})],1):_c(VProgressCircular,{attrs:{"color":"warning","indeterminate":""}})],1):_vm._e(),_c(VBtn,{staticClass:"mr-4",attrs:{"color":_vm.filterButtonColor,"outlined":""},on:{"click":_vm.handleOpenModalFilterTerms}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_vm._v(" Filtrar ")],1),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.isLoadingTableData,"loading-text":"Carregando dados...","fixed-header":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":"","color":"#000000A1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"#E7E3FC"},on:{"click":function($event){return _vm.handleOpenModalVisualizeTerm(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar termo")])]),_c(VTooltip,{attrs:{"top":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.sendingSignedDocto(item)}}},'v-btn',attrs,false),on),[(!item.isLoading)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiFileSendOutline)+" ")]):_c(VProgressCircular,{attrs:{"color":"success","indeterminate":""}})],1)]}}],null,true)},[_c('span',[_vm._v("Enviar documento assinado")])]),_c(VTooltip,{attrs:{"top":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.handleOpenUpdateModal(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar termo")])])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-5"},[_c('NoDataInterfaceEditable',{attrs:{"title":"Nenhum  registro encontrado!","subtitle":"Por favor, verifique os filtros de pesquisa."}})],1)]},proxy:true},{key:"item.proposal_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.proposal_number ? _vm.returnProposalNumberFormatted(item.proposal_number) : '-'))])]}},{key:"item.life_insurance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.life_insurance ? 'SIM' : '-'))])]}},{key:"item.bid_fixed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.bid_fixed ? 'SIM' : '-'))])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.client_name ? item.client_name.toUpperCase() : '-'))])]}},{key:"item.client_phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.client_phone ? item.client_phone.toUpperCase() : '-'))])]}},{key:"item.vendor_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.vendor_name ? item.vendor_name.toUpperCase() : '-'))])]}},{key:"item.contract_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.contract_date ? _vm.returnDateFormatted(item.contract_date): '-'))])]}},{key:"item.term_status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge my-5",staticStyle:{"border-radius":"6px","font-size":"14px","font-weight":"500"},attrs:{"color":_vm.getStatusColor(item),"text":""}},[_vm._v(" "+_vm._s(item.term_status ? item.term_status.toUpperCase() : '')+" ")])]}}])})],1),_c(VDialog,{attrs:{"width":"600px","transition":"dialog-transition"},model:{value:(_vm.showModalFilterTerms),callback:function ($$v) {_vm.showModalFilterTerms=$$v},expression:"showModalFilterTerms"}},[_c('ModalFilterTerms',{ref:"ModalFilterTermsRef",attrs:{"list-store":_vm.listStore,"list-vehicle-model":_vm.listVehicleModel,"list-region":_vm.listRegion,"vendor-list":_vm.vendorList},on:{"close":function($event){_vm.showModalFilterTerms = false},"filterData":_vm.handleFilterData}})],1),_c(VDialog,{key:_vm.updateModalKey,attrs:{"width":"900px","transition":"dialog-transition"},model:{value:(_vm.showModalUpdateTerm),callback:function ($$v) {_vm.showModalUpdateTerm=$$v},expression:"showModalUpdateTerm"}},[_c('ModalUpdateTerm',{attrs:{"list-store":_vm.listStore,"list-vehicle-model":_vm.listVehicleModel,"list-region":_vm.listRegion,"item":_vm.selectedItem},on:{"close":function($event){_vm.showModalUpdateTerm = false}}})],1),_c(VDialog,{key:_vm.visualizeModalKey,attrs:{"width":"900px","transition":"dialog-transition"},model:{value:(_vm.showModalVisualizeTerm),callback:function ($$v) {_vm.showModalVisualizeTerm=$$v},expression:"showModalVisualizeTerm"}},[_c('ModalVisualizeTerm',{attrs:{"list-store":_vm.listStore,"list-vehicle-model":_vm.listVehicleModel,"list-region":_vm.listRegion,"item":_vm.selectedItem},on:{"close":function($event){_vm.showModalVisualizeTerm = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }