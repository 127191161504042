<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Filtrar termos
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="mx-7">
      <v-form ref="form">
        <div class="pb-6">
          <Separator label="Informações do vendedor" />
        </div>
        <div
          class="d-flex"
          style=" gap: 10px;"
        >
          <v-autocomplete
            v-model="vendor_name"
            :items="vendorList"
            item-text="name"
            item-value="name"
            label="Nome do vendedor"
            clearable
            dense
            outlined
          ></v-autocomplete>
          <v-text-field
            v-model="vendor_cpf"
            outlined
            dense
            label="CPF do vendedor"
          />
        </div>
        <div class="pb-6">
          <Separator label="Informações do cliente" />
        </div>
        <v-text-field
          v-model="client_name"
          outlined
          dense
          label="Nome do cliente"
        />
        <div
          class="d-flex"
          style="gap: 10px; align-items: center;"
        >
          <v-text-field
            v-model="cpf_cnpj"
            outlined
            dense
            label="CPF/CNPJ"
          />
          <v-text-field
            v-model="client_phone"
            outlined
            dense
            label="Telefone"
          />
          <v-text-field
            v-model="proposal_number"
            outlined
            dense
            label="Proposta"
          />
        </div>
        <div class="pb-6">
          <Separator label="informações de localidade" />
        </div>

        <v-autocomplete
          v-model="region_id"
          :items="listRegion"
          label="Filial"
          item-text="region"
          item-value="id"
          outlined
          dense
        />
        <v-autocomplete
          v-model="fantasy_name"
          :items="listStore"
          item-text="fantasy_name"
          item-value="fantasy_name"
          label="Loja"
          outlined
          dense
        />

        <div class="pb-4">
          <Separator label="informações do contrato" />
        </div>
        <h3 style="font-size: 15px; font-weight: 500;">
          Por data de criação do contrato:
        </h3>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="initialDateFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(initialDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="initial_date_contract"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="finalDateFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(finalDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="final_date_contract"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <h3 style="font-size: 15px; font-weight: 500;">
          Por data de validação do contrato:
        </h3>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedInitialDateFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedInitialDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="initial_date_validated"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu4"
            v-model="menu4"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedFinalDateFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedFinalDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="final_date_validated"
              @input="menu4 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class="d-flex"
          style="gap: 77px;"
        >
          <v-switch
            v-model="bid_fixed"
            class="mt-0"
            color="success"
            inset
            dense
            label="Lance fixo"
          />
          <v-switch
            v-model="life_insurance"
            class="mt-0"
            color="purple"
            inset
            dense
            label="Seguro de vida"
          />
        </div>
        <div class="pb-4">
          <Separator label="Status do termo" />
        </div>
        <div
          class="d-flex"
          style="justify-content: space-between;"
        >
          <v-radio-group
            v-model="radioGroup"
            row
          >
            <v-radio
              value="Aberta"
              label="Em aberto"
            ></v-radio>
            <v-radio
              value="Assinada"
              label="Assinada"
            ></v-radio>
            <v-radio
              value="Validada"
              label="Validado"
            ></v-radio>
            <v-radio
              value="Inutilizada"
              label="Inutilizado"
            ></v-radio>
          </v-radio-group>
          <!-- <v-switch
            v-model="isOpened"
            class="mt-0"
            color="info"
            inset
            dense
            label="Em aberto"
          />
          <v-switch
            v-model="isValidated"
            class="mt-0"
            color="success"
            inset
            dense
            label="Validado"
          />
          <v-switch
            v-model="isUnused"
            class="mt-0"
            color="error"
            inset
            dense
            label="Inutilizado"
          /> -->
        </div>
        <div
          class=" d-flex justify-between pb-4"
        >
          <v-btn
            v-if="isAnyFieldFilled"
            color="error"
            @click="handleClearFilter"
          >
            <span>LIMPAR</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            :disabled="!isFilterButtonEnabled || isLoading"
            @click="handleFilter(true)"
          >
            <span v-if="!isLoading">FILTRAR</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
              size="20"
            ></v-progress-circular>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  name: 'ModalFilterMySolicitations',
  components: { Separator },
  mixins: [formatters, messages],
  props: {
    listStore: {
      type: Array,
      default: () => [],
    },
    listRegion: {
      type: Array,
      default: () => [],
    },
    vendorList: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose, mdiCalendar,
      },
      isLoading: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,

      emmitedStates: {
        data: {},
        isFiltered: false,
      },

      vendor_name: '',
      vendor_cpf: '',
      client_name: '',
      cpf_cnpj: '',
      client_phone: '',
      proposal_number: '',
      region_id: '',
      fantasy_name: '',
      initial_date_contract: '',
      final_date_contract: '',
      initial_date_validated: '',
      final_date_validated: '',
      bid_fixed: false,
      life_insurance: false,
      isOpened: false,
      isValidated: false,
      isUnused: false,
      radioGroup: '',

      initialDateFormatted: '',
      finalDateFormatted: '',
      expectedInitialDateFormatted: '',
      expectedFinalDateFormatted: '',

    }
  },
  computed: {
    isAnyFieldFilled() {
      return (
        this.client_name
        || this.vendor_cpf
        || this.vendor_name
        || this.cpf_cnpj
        || this.client_phone
        || this.proposal_number
        || this.region_id
        || this.fantasy_name
        || this.initial_date_contract
        || this.final_date_contract
        || this.initial_date_validated
        || this.final_date_validated
        || this.bid_fixed
        || this.life_insurance
        || this.radioGroup
      )
    },
    isFilterButtonEnabled() {
      return this.isAnyFieldFilled
    },
  },

  watch: {
    final_date_contract() {
      this.finalDateFormatted = this.formatDate(this.final_date_contract)
    },
    initial_date_contract() {
      this.initialDateFormatted = this.formatDate(this.initial_date_contract)
    },
    final_date_validated() {
      this.expectedFinalDateFormatted = this.formatDate(this.final_date_validated)
    },
    initial_date_validated() {
      this.expectedInitialDateFormatted = this.formatDate(this.initial_date_validated)
    },
  },
  methods: {
    async handleFilter(isResetFilter = false) {
      this.isLoading = true
      const body = {
        vendor_name: this.vendor_name,
        vendor_cpf: this.vendor_cpf,
        client_name: this.client_name,
        cpf_cnpj: this.cpf_cnpj,
        client_phone: this.client_phone,
        proposal_number: this.proposal_number,
        region_id: this.region_id,
        fantasy_name: this.fantasy_name,
        initial_date_contract: this.initial_date_contract,
        final_date_contract: this.final_date_contract,
        initial_date_validated: this.initial_date_validated,
        final_date_validated: this.final_date_validated,
        bid_fixed: this.bid_fixed,
        life_insurance: this.life_insurance,
        term_status: this.radioGroup,
      }
      try {
        const { data } = await axiosIns.post('/api/v1/integration/lgpd_term/find_term', body)
        this.emmitedStates.data = data.data
        this.emmitedStates.isFiltered = isResetFilter
        this.$emit('filterData', this.emmitedStates)
        if (isResetFilter) {
          this.closeModal()
        }
      } catch (error) {
        console.error('Erro ao buscar dados do filtro solicitado pelo usuário', error)
      } finally {
        this.isLoading = false
      }
    },

    // returnBasedOnStatus(isOpened, isValidated, isUnused) {
    //   if (isOpened) return 'Aberta'
    //   if (isValidated) return 'Validada'
    //   if (isUnused) return 'Inutilizada'

    //   return ''
    // },
    closeModal() {
      this.$emit('close')
    },
    handleClearFilter() {
      if (!this.emmitedStates.isFiltered) {
        this.handleClearFilterFields()

        return
      }
      this.$refs.form.reset()
      this.initial_date_contract = ''
      this.final_date_contract = ''
      this.final_date_validated = ''
      this.initial_date_validated = ''
      this.emmitedStates.data = []
      this.emmitedStates.isFiltered = false
      this.$emit('filterData', this.emmitedStates)
    },
    handleClearFilterFields() {
      this.$refs.form.reset()
      this.final_date_validated = ''
      this.initial_date_validated = ''
      this.initial_date_contract = ''
      this.final_date_contract = ''
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },

}
</script>

<style>

</style>
